import React from "react";
import { useTranslation } from "react-i18next";
import ResponsiveMedia from "utils/responsive_media";
import { handleVoucherPlatform } from "..";
import { Box } from "../../../components/style/layoutStyle";
import { ItemDesc, ItemInfo, ItemTitle, ListItemBox } from "./style";

const InfoList = ({ data }) => {
    const { t } = useTranslation();
    const { isMobile } = ResponsiveMedia();

    return (
        <Box flexDirection={"column"} gap={isMobile ? "8px" : "16px"} padding={isMobile ? "40px 0 8px" : "60px 0 16px"}>
            {data.map((value, index) => {
                return (
                    <ListItemBox key={index} padding={isMobile ? "21px 24px" : "33px 60px"} gap={isMobile ? "26px" : "66px"}>
                        <ItemTitle size={isMobile ? "12px" : "18px"}>{t(value.title)}</ItemTitle>
                        <ItemDesc size={isMobile ? "14px" : "22px"} whiteSpace={"normal"} style={{ wordBreak: isMobile ? "keep-all" : "normal" }}>
                            {value.link && (
                                <ItemDesc size={isMobile ? "14px" : "22px"} style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => handleVoucherPlatform()}>
                                    {value.link}
                                </ItemDesc>
                            )}
                            {t(value.desc)}
                            {value.info && <ItemInfo size={isMobile ? "12px" : "18px"}>{t(value.info)}</ItemInfo>}
                        </ItemDesc>
                    </ListItemBox>
                );
            })}
        </Box>
    );
};

export default InfoList;
