import React from "react";
import ResponsiveMedia from "utils/responsive_media";
import SquareButton from "../../../components/button/squareButton";
import { Box } from "../../../components/style/layoutStyle";
import { TextBold, TextNormal } from "../../../components/style/textStyle";
import { showChannelTalk } from "hooks/useChannelIO";
import { CONTACT_US_TITLE } from "constants/data/common";
import { useTranslation } from "react-i18next";

const TitleBox = ({ title, desc }) => {
    const { isMobile } = ResponsiveMedia();
    const { t } = useTranslation();
    return (
        <Box flexDirection={"column"}>
            <TextBold type={isMobile ? "26" : "36"} textAlign={"center"} padding={isMobile ? "0 0 12px 0" : "0 0 32px 0"} color={"#1e1e1e"}>
                {title}
            </TextBold>
            <TextNormal type={isMobile ? "16" : "22"} textAlign={"center"} padding={isMobile ? "0 0 16px 0" : "0 0 16px 0"} color={"#1e1e1e"}>
                {desc}
            </TextNormal>
            <div style={{ boxShadow: "0 3px 10px 0 rgba(0, 0, 0, 0.1)" }}>
                <SquareButton
                    width={"178px"}
                    height={"48px"}
                    fontSize={isMobile === "true" ? "14px" : "16px"}
                    bgColor={"#fff"}
                    hBgColor={"#fff"}
                    aBgColor={"#fff"}
                    color={"#1e1e1e"}
                    hColor={"#1e1e1e"}
                    aColor={"#1e1e1e"}
                    text={t("CONTACT_US_TITLE")}
                    onClickEvent={() => showChannelTalk()}
                />
            </div>
        </Box>
    );
};

export default TitleBox;
