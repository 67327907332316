import React from "react";
import ResponsiveMedia from "utils/responsive_media";
import { TextBold } from "../../../components/style/textStyle";
import { Box, Container } from "../../../components/style/layoutStyle";
import { Button, ButtonTitle, CardDivider, SupportBox, SupportText } from "./style";
import { IC_ARROW_RIGHT_LONG_WHITE } from "../../../constants/media/icon";
import { VOUCHER_SUPPORT_TEXT, VOUCHER_SUPPORT_TEXT_MOBILE } from "../../../constants/data/voucher";
import Image from "components/image/image";
import VoucherPriceCard from "./voucherPriceCard";
import { showChannelTalk } from "hooks/useChannelIO";
import { CONTACT_US_TITLE } from "constants/data/common";
import { useTranslation } from "react-i18next";

const Price = ({ data }) => {
    const { t } = useTranslation();
    const { isMobile } = ResponsiveMedia();

    return (
        <Container height={"auto"} bgColor={"#ffffff"} padding={isMobile ? "20px 20px" : "40px 20px 40px"}>
            <Box width={"100%"} maxWidth={"100%"} padding={isMobile ? "40px 20px 60px" : "120px 0 100px"} bgColor={"#faf7ef"} flexDirection={"column"} justifycontent={"center"} alignitems={"center"} style={{ borderRadius: "20px" }}>
                <TextBold type={isMobile ? "26" : "36"} textAlign={"center"} padding={isMobile ? "0 0 28px 0" : "0 0 32px 0"} color={"#1e1e1e"}>
                    {t(data.title)}
                </TextBold>
                <VoucherPriceCard data={data.items} />
                <CardDivider style={{ maxWidth: "904px", margin: "32px 0" }} />
                <SupportBox justifycontent={isMobile ? "center" : "space-between"} flexDirection={isMobile ? "column" : "row"} maxHeight={isMobile ? "146px" : "83px"} padding={isMobile ? "20px 43px" : "18px 28px"} gap={isMobile && "16px"}>
                    <SupportText size={isMobile ? "14px" : "18px"} textAlign={isMobile ? "center" : "left"} style={{ whiteSpace: isMobile ? "pre-line" : "normal" }}>
                        {isMobile ? t("VOUCHER_SUPPORT_TEXT_MOBILE") : t("VOUCHER_SUPPORT_TEXT")}
                    </SupportText>
                    <Button width={"178px"} bgColor={"#713838"} onClick={() => showChannelTalk()}>
                        <ButtonTitle color={"#fff"}>{t("CONTACT_US_TITLE")}</ButtonTitle>
                        <Image filename={IC_ARROW_RIGHT_LONG_WHITE} alt={t(data.title)} style={{ width: "32px" }} />
                    </Button>
                </SupportBox>
            </Box>
        </Container>
    );
};

export default Price;
