import React from "react";
import CardComp from "../main/cardComp";
import Benefit from "./benefit";
import Features from "./features";
import Price from "./price";
import TopBanner from "./topBanner";

export const handleVoucherSupport = () => {
    window.open("https://docs.google.com/forms/d/e/1FAIpQLSdeb42mou-z6emG2jzyXjnK0Uzu5y2IboiJtmMIBfARLzabsg/viewform", "_blank");
};

export const handleVoucherPlatform = () => {
    window.open("https://www.k-voucher.kr/index.php", "_blank");
};

export const handleVoucherSearch = () => {
    window.open("https://www.k-voucher.kr/index.php/page/productSearch?search_category=bmm.cmpny_nm&search_keyword=도뉴", "_blank");
};

const Voucher = ({ title, featureDesc, featureCard, compCard, benefit, price }) => {
    return (
        <>
            <TopBanner data={title} />
            <Benefit data={benefit} />
            <Price data={price} />
            <Features desc={featureDesc} card={featureCard} />
            <CardComp data={compCard} />
        </>
    );
};

export default Voucher;
