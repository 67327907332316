import React from "react";
import ResponsiveMedia from "utils/responsive_media";
import { Box } from "../../../components/style/layoutStyle";
import { ItemDesc, ItemInfo, ItemTitle, ListItemBox, Step } from "./style";
import { IC_VOUCHER_PURCHASE_ARROW, IC_VOUCHER_PURCHASE_DOWN_ARROW } from "../../../constants/media/icon";
import Image from "components/image/image";
import { handleVoucherSearch } from "..";
import { useTranslation } from "react-i18next";

const Purchase = ({ data }) => {
    const { t } = useTranslation();
    const { isMobile } = ResponsiveMedia();
    return (
        <ListItemBox flexDirection={"column"} padding={isMobile ? "20px 24px" : "33px 60px"} maxHeight={isMobile ? "auto" : "227px"}>
            <Box gap={"24px"} alignitems={"flex-start"} justifycontent={"flex-start"} flexDirection={"column"}>
                <ItemTitle size={isMobile ? "12px" : "18px"} maxWidth={"auto"} style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                    {t(data.title)}
                    <ItemInfo size={isMobile ? "12px" : "16px"}>{t(data.desc)}</ItemInfo>
                </ItemTitle>
                <Box flexDirection={isMobile ? "column" : "row"}>
                    {data.items.map((value, index) => {
                        return (
                            <>
                                {isMobile ? (
                                    <Box key={index} width={"100%"} flexDirection={"row"} alignitems={"flex-start"} justifycontent={"flex-start"} padding={"0 0 12px 0"} gap={"12px"}>
                                        <Box maxWidth={"60px"} flexDirection={"column"} alignitems={"center"} justifycontent={"flex-start"} gap={"12px"}>
                                            <Step isMobile={isMobile}>{"STEP " + (index + 1)}</Step>
                                            {index < data.items.length - 1 && <Image filename={IC_VOUCHER_PURCHASE_DOWN_ARROW} alt={value.title} style={{ width: "24px" }} />}
                                        </Box>
                                        <Box width={"auto"} flexDirection={"row"} justifycontent={"center"}>
                                            <ItemDesc size={"14px"} whiteSpace={"normal"} style={{ wordBreak: "keep-all" }}>
                                                {value.link && (
                                                    <ItemDesc size={"14px"} style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => handleVoucherSearch()}>
                                                        {value.link}
                                                    </ItemDesc>
                                                )}
                                                {t(value.title)}
                                            </ItemDesc>
                                        </Box>
                                    </Box>
                                ) : (
                                    <Box key={index} width={"auto"} flexDirection={"column"} alignitems={"flex-start"} justifycontent={"flex-start"} gap={"16px"}>
                                        <Step>{"STEP " + (index + 1)}</Step>
                                        <Box width={"auto"} flexDirection={"row"} justifycontent={"center"}>
                                            <ItemDesc size={"16px"} whiteSpace={"pre-line"}>
                                                {value.link && (
                                                    <ItemDesc size={"16px"} style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => handleVoucherSearch()}>
                                                        {value.link}
                                                    </ItemDesc>
                                                )}
                                                {t(value.title)}
                                            </ItemDesc>
                                            {index < data.items.length - 1 && <Image filename={IC_VOUCHER_PURCHASE_ARROW} alt={value.title} style={{ width: "24px", margin: "0 18px 0" }} />}
                                        </Box>
                                    </Box>
                                )}
                            </>
                        );
                    })}
                </Box>
            </Box>
        </ListItemBox>
    );
};

export default Purchase;
