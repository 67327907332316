import React from "react";
import ResponsiveMedia from "utils/responsive_media";
import { Container, Box } from "components/style/layoutStyle";
import { LogoBox, TitleImgWrapper, TopContentWrapper } from "./style";
import { IC_VOUCHER_GNB_LOGO, IC_KVOUCHER, IC_X_GR } from "../../../constants/media/icon";
import { TextBold, TextNormal } from "../../../components/style/textStyle";
import Image from "components/image/image";
import ButtonBox from "./buttonBox";
import { useTranslation } from "react-i18next";

const TopBanner = ({ data }) => {
    const { t } = useTranslation();
    const { isMobile } = ResponsiveMedia();

    return (
        <Container height={isMobile ? "auto" : "740px"} bgColor={"#faf7ef"} padding={isMobile ? "50px 20px" : "0 20px"}>
            <Box gap={isMobile && "20px"} flexDirection={isMobile ? "column" : "row"}>
                <TopContentWrapper justifycontent={isMobile ? "center" : "flex-start"}>
                    <TextBold style={{ ...(isMobile && { marginTop: "20px" }) }} type={isMobile ? "28" : "42"} padding={"0 0 16px 0"} textAlign={isMobile ? "center" : "left"} color={"#1e1e1e"}>
                        {t(data.title)}
                    </TextBold>
                    <TextNormal type={isMobile ? "14" : "22"} padding={isMobile ? "0 0 32px 0" : "0 0 64px 0"} textAlign={isMobile ? "center" : "left"} color={"#1e1e1e"}>
                        {t(data.desc)}
                    </TextNormal>
                    <ButtonBox />
                </TopContentWrapper>
                <TitleImgWrapper>
                    <Image filename={data.img} alt={t(data.title)} />
                </TitleImgWrapper>
            </Box>
        </Container>
    );
};

export default TopBanner;
