import React from "react";
import ResponsiveMedia from "utils/responsive_media";
import { Container, Box } from "components/style/layoutStyle";
import TitleBox from "./titleBox";
import InfoList from "./infoList";
import Purchase from "./purchase";
import { useTranslation } from "react-i18next";

const Benefit = ({ data }) => {
    const { t } = useTranslation();
    const { isMobile } = ResponsiveMedia();

    return (
        <Container bgColor={"#f5f8fd"} padding={isMobile ? "84px 20px 60px" : "144px 20px 150px"}>
            <Box flexDirection={"column"}>
                <TitleBox title={isMobile ? t(data.title_mobile) : t(data.title)} desc={isMobile ? t(data.desc_mobile) : t(data.desc)} />
                <InfoList data={data.items} />
                <Purchase data={data.purchase} />
            </Box>
        </Container>
    );
};

export default Benefit;
