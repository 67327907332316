import styled from "styled-components";

export const CardWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Card = styled.div`
    width: ${(props) => props.width};
    height: 397px;
    border-radius: 8px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.08);
    background-color: #fff;
`;

export const CardTopper = styled.div`
    width: 100%;
    height: 10px;
    border-radius: 8px 8px 0 0;
    background-color: #713838;
`;

export const CardTitleWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${(props) =>
        props.mobile
            ? `
        height: 23px;
        padding: 0 36px;
        margin-bottom: 20px;
    `
            : `
        height: 22px;
        padding: 0 30px;
        margin-bottom: 12px;
    `}
`;

export const CardDivider = styled.div`
    width: 100%;
    padding: 0.5px 0;
    background-color: #ddd;
    margin: 20px 0;
`;

export const CardContentWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const CardTitleText = styled.div`
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #713838;
`;

export const CardInfoTitle = styled.div`
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.42px;
    text-align: left;
    color: ${(props) => props.color};

    > span {
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.14px;
        text-align: left;
        color: #1e1e1e;
        margin-left: 2px;
    }
`;

export const CardPriceWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: cneter;
    gap: 4px;
`;

export const CardSquareBox = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background-color: #e2d1c6;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
`;

export const CardPrice = styled.div`
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.33px;
    text-align: right;
    color: ${(props) => props.color};

    > .won {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.36px;
        text-align: left;
        color: #707070;
        margin-left: 2px;
    }
`;

export const SupportBox = styled.div`
    width: 100%;
    height: 100%;
    max-width: ${(props) => props.maxWidth || `904px`};
    max-height: ${(props) => props.maxHeight || `83px`};
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.justifycontent};
    flex-direction: ${(props) => props.flexDirection};
    padding: ${(props) => props.padding};
    gap: ${(props) => props.gap};
`;

export const SupportText = styled.div`
    font-size: ${(props) => props.size};
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: -0.54px;
    text-align: ${(props) => props.textAlign};
    color: #1e1e1e;
`;

export const Button = styled.div`
    width: ${(props) => props.width};
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: ${(props) => props.bgColor};
    border-radius: 4px;
    cursor: pointer;
`;

export const ButtonTitle = styled.div`
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.54px;
    text-align: center;
    color: ${(props) => props.color};
`;
