import React from "react"
import ResponsiveMedia from "utils/responsive_media"
import { Box } from "../../../components/style/layoutStyle"
import { TextNormal } from "../../../components/style/textStyle"
import {
    Card,
    CardContentWrapper,
    CardDivider,
    CardInfoTitle,
    CardPrice,
    CardPriceWrapper,
    CardSquareBox,
    CardTitleText,
    CardTopper,
    CardWrapper,
} from "./style"

const VoucherPriceCard = ({ data }) => {
    const { isMobile } = ResponsiveMedia()

    const currencyConvert = price => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    return (
        <Box flexDirection={"column"} alignitems={"center"} justifycontent={"center"}>
            <div style={{ width: isMobile ? "290px" : "100%", maxWidth: "904px", display: "flex", justifyContent: "flex-end" }}>
                <TextNormal type={isMobile ? 12 : 14} textAlign={"right"} padding={"0 0 12px"} color={"#999"}>
                    *부가세 미포함
                </TextNormal>
            </div>
            <Box flexDirection={isMobile ? "column" : "row"} alignitems={"center"} justifycontent={"center"} gap={"17px"}>
                {data.length > 0 &&
                    data.map((value, index) => {
                        return (
                            <CardWrapper key={index} style={isMobile && { width: "100%" }}>
                                <Card width={"290px"}>
                                    <CardTopper />
                                    <Box flexDirection={"column"} alignitems={"center"} justifycontent={"center"} padding={"20px 28px 30px"}>
                                        <CardTitleText>{value.plan_name}</CardTitleText>
                                        <CardDivider />
                                        <CardContentWrapper>
                                            <CardInfoTitle color={"#999"}>연간 요금</CardInfoTitle>
                                            <CardPrice color={"#e2d1c6"}>
                                                <span style={{ textDecoration: "line-through" }}>{currencyConvert(value.plan_price[0])}</span>
                                                <span className="won">원</span>
                                            </CardPrice>
                                        </CardContentWrapper>
                                        <CardContentWrapper>
                                            <CardInfoTitle color={"#999"}>
                                                정부 지원<span>70%</span>
                                            </CardInfoTitle>
                                            <CardPriceWrapper>
                                                <CardSquareBox>-</CardSquareBox>
                                                <CardPrice color={"#e2d1c6"}>
                                                    <span style={{ textDecoration: "line-through" }}>{currencyConvert(value.plan_price[1])}</span>
                                                    <span className="won">원</span>
                                                </CardPrice>
                                            </CardPriceWrapper>
                                        </CardContentWrapper>
                                        <CardDivider />
                                        <CardContentWrapper>
                                            <CardInfoTitle color={"#999"}>
                                                본인 부담<span>30%</span>
                                            </CardInfoTitle>
                                            <CardPriceWrapper>
                                                <CardSquareBox>=</CardSquareBox>
                                                <CardPrice color={"#1e1e1e"} style={{ paddingLeft: value.plan_name === "PRO III" ? "17px" : "0" }}>
                                                    {currencyConvert(value.plan_price[2])}
                                                    <span className="won">원</span>
                                                </CardPrice>
                                            </CardPriceWrapper>
                                        </CardContentWrapper>
                                        <CardDivider />
                                        <Box flexDirection={"column"} alignitems={"center"} justifycontent={"center"} gap={"8px"}>
                                            <CardContentWrapper>
                                                <CardInfoTitle color={"#1e1e1e"}>계약 전송 건 수</CardInfoTitle>
                                                <CardInfoTitle color={"#1e1e1e"}>{value.contract === 0 ? "무제한" : value.contract + "건/월"}</CardInfoTitle>
                                            </CardContentWrapper>
                                            <CardContentWrapper>
                                                <CardInfoTitle color={"#1e1e1e"}>첨부파일</CardInfoTitle>
                                                <CardInfoTitle color={"#1e1e1e"}>무제한</CardInfoTitle>
                                            </CardContentWrapper>
                                            <CardContentWrapper>
                                                <CardInfoTitle color={"#1e1e1e"}>그룹 관리</CardInfoTitle>
                                                <CardInfoTitle color={"#1e1e1e"}>{value.group_org + "명"}</CardInfoTitle>
                                            </CardContentWrapper>
                                        </Box>
                                    </Box>
                                </Card>
                            </CardWrapper>
                        )
                    })}
            </Box>
        </Box>
    )
}

export default VoucherPriceCard
