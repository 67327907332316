import * as React from "react";
import Seo from "components/seo";
import { VOUCHER_META, VOUCHER_META_EN } from "../../constants/data/meta_data";
import { VOUCHER_BENEFIT, VOUCHER_COMPANY_CARD, VOUCHER_FEATURES, VOUCHER_FEATURES_CARD, VOUCHER_PRICE, VOUCHER_PROMOTION_BANNER_SUBTITLE, VOUCHER_PROMOTION_BANNER_TITLE, VOUCHER_TITLE } from "../../constants/data/voucher";
import Voucher from "../../organisms/voucher";
import LayoutBasic from "../../templates/layoutBasic";
import PromotionBanner from "../../organisms/banner/promotionBanner";
import ServiceBanner from "../../organisms/banner/serviceBanner";
import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

const VoucherPage = () => {
    const { i18n } = useTranslation();

    const seoData = useMemo(() => {
        if (i18n.language === "en") return VOUCHER_META_EN;

        return VOUCHER_META;
    }, [i18n.language]);

    return (
        <LayoutBasic>
            <Seo title={seoData.title} description={seoData.description} canonical={seoData.canonical} kakaoKeyword={seoData.kakao} />
            <Voucher title={VOUCHER_TITLE} featureDesc={VOUCHER_FEATURES} featureCard={VOUCHER_FEATURES_CARD} compCard={VOUCHER_COMPANY_CARD} benefit={VOUCHER_BENEFIT} price={VOUCHER_PRICE} />
            <PromotionBanner title={VOUCHER_PROMOTION_BANNER_TITLE} subtitle={VOUCHER_PROMOTION_BANNER_SUBTITLE} />
            <ServiceBanner />
        </LayoutBasic>
    );
};

export default VoucherPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
